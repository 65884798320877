import { createI18n } from 'vue-i18n'
import messages from './index'
const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('language') || 'zh_cn', // 设置语种
  messages, // 设置全局当地语言包,
  fallbackLocale: 'zh_cn',
});

export default i18n
