import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import i18n from './lang/i18n.js'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import 'animate.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { VueMasonryPlugin } from 'vue-masonry';


// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// #import { Carousel, CarouselItem } from 'element-ui';

// import electron from 'electron';
// import { app, BrowserWindow } from 'electron';

//import path from 'path-browserify';

// const joinedPath = path.join('folder', 'file.txt');
// console.log(joinedPath); // Outputs: 'folder/file.txt'


const app = createApp(App)
// const electron = require('electron');
// global.electron = electron;


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(ElementPlus)
app.use(VueMasonryPlugin)

app.mount('#app')

// app.use(ElementUI);
// app.use(electron);
