<template>
  <el-container>
    <el-header height="60" class="main-head">
      <el-row>
        <el-menu>
          <el-menu-item @click="changeCollapse">
            <el-icon><Expand v-show="isCollapse"/><Fold v-show="!isCollapse"/></el-icon>
          </el-menu-item>
        </el-menu>
        <img src="../src/assets/logo/logo-0.png" alt="Logo" style="width: 50px; height: 50px; margin-left: 20px;"/>
        <el-row align="middle" :gutter=20 class="banner-row">
          <el-col :span="2" :offset="6">
            <el-select v-model="searchType">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-col>
          <el-col :span="9">
            <el-input></el-input>
          </el-col>
          <el-col :span="1">
            <el-button :icon="Search" circle />
          </el-col>
        </el-row>
        <hr class="top-line" />
      </el-row>
    </el-header>

    <el-container>
      <el-aside class="main-aside" :class="{'isCollapse-true': isCollapse}">
        <div class="aside-content">
          <el-menu
              default-active="1"
              class="el-menu-vertical-demo"
              :collapse="isCollapse"
              @select="handleSelect"
          >
            <el-menu-item v-for="item in menus"
                          :key="item.value"
                          :index="item.index">
              <el-icon>
                <component :is="item.icon"></component>
              </el-icon>
              <template #title>{{ item.title }}</template>
            </el-menu-item>
          </el-menu>
          <!--          <div class="copyright-info" style="font-style: italic; color: #666;">© Copyrights 2024 </div>-->
          <div class="copyright-info" style="font-style: italic; color: #666;" v-if='!isCollapse' >动图技术（南京）有限公司. </div>
          <!--          <div class="copyright-info" style="font-style: italic; color: #666;">All rights reserved</div>-->
          <div class="copyright-info" style="font-style: italic; color: #666;" v-if='!isCollapse' >苏ICP备2024117161号</div>
          <div class="copyright-info" style="font-style: italic; color: #666;" v-if='!isCollapse' >邮箱:ROBO-AI@asirobo.cn</div>
        </div>
      </el-aside>

      <el-scrollbar class="scrollable-container">

        <el-container class="right-body">
          <el-main>
            <RouterView />
          </el-main>
        </el-container>

      </el-scrollbar>

    </el-container>

  </el-container>


</template>

<script lang="ts" setup>
import { Search } from '@element-plus/icons-vue';
import { ref } from 'vue';
import { RouterView, useRouter } from 'vue-router';

const router = useRouter();
const options = [
  {
    value: 'Models',
    label: '模型',
  },
  {
    value: 'Images',
    label: '图片',
  },
  // {
  //   value: 'Articles',
  //   label: '文章',
  // },
  {
    value: 'Users',
    label: '用户',
  },
  // {
  //   value: 'Bounties',
  //   label: '悬赏',
  // }
];

const searchType = ref('Models');
const menus = [

  {
    index: '1',
    icon: 'home-filled',
    title: '动图主页',
    path: '/'
  },
  {
    index: '7',
    icon: 'crop',
    title: '数字动图',
    path: '/dongtu'
  },
  {
    index: '2',
    icon: 'platform',
    title: '动画模型',
    path: '/models'
  },

  {
    index: '3',
    icon: 'picture-filled',
    title: 'AI出图',
    path: '/images'
  },

  {
    index: '4',
    icon: 'list',
    title: 'VR场景',
    path: '/vr'
  },

  {
    index: '5',
    icon: 'van',
    title: '元宇宙小镇',
    path: '/meta'
  }
    , {
      index: '6',
      icon: 'cloudy',
      title: '元宇宙商场',
      path: '/metb'
    },

   {
    index: '8',
    icon: 'smoking',
    title: '手谈对弈',
    path: '/game'
  },
  {
    index: '9',
    icon: 'Fries',
    title: '日升月落',
    path: '/planet'
  },
  {
    index: '10',
    icon: 'hot-water',
    title: '加入我们',
    path: '/hr'
  },
];

const isCollapse = ref(false);

const changeCollapse = () => {
  console.log('Changing collapse state. Current value:', isCollapse.value);
  isCollapse.value =!isCollapse.value;

  // 手动更新右侧内容的类名
  const rightBody = document.querySelector('.right-body');
  if (isCollapse.value) {
    rightBody.classList.add('isCollapse-true');
  } else {
    rightBody.classList.remove('isCollapse-true');
  }
};



/*const handleSelect = (key) => {
  menus.forEach(menu => {
    if (menu.index === key) {
      router.push(menu.path);
    }
  });
};*/

const handleSelect = (key) => {
  if (isCollapse.value) {
    // 如果当前是收缩状态，则展开菜单栏
    isCollapse.value = false;
  }
  menus.forEach(menu => {
    if (menu.index === key) {
      router.push(menu.path);
    }
  });


};


/*onMounted(() => {
  // 在组件挂载时确保 isCollapse 初始值为 true
  isCollapse.value = false;
});*/

</script>

<style scoped>
/* 全局重置 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* 头部样式 */
.main-head {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  z-index: 10;
  position: fixed;
  width: 100%;
}

/* 顶部搜索行 */
.banner-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
  padding-left: 50px;
}

/*.main-aside {
  width: 250px;
  background-color: #f9f9f9;
  position: fixed;
  top: 60px; !* 与 header 保持一致的高度，避免内容被遮挡 *!
  bottom: 0;
  left: 0;
  padding-top: 20px; !* 为内容留出空间，避免顶部遮挡 *!
  z-index: 9;
  transition: width 0.3s ease; !* 添加过渡效果 *!
}*/
.main-aside {
  width: 250px;
  background-color: #f9f9f9;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  padding-top: 20px;
  z-index: 9;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}


.el-menu-vertical-demo {
  border-right: none!important;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}

.aside-content {
  overflow-y: scroll;
  height: 80%;
  max-height: none;
  min-height: 101px;
}

.aside-content::-webkit-scrollbar {
  width: 6px;
}

.aside-content::-webkit-scrollbar-track {
  background-color: #eaeaea; /* 稍微调淡滚动条轨道颜色 */
}

.aside-content::-webkit-scrollbar-thumb {
  background-color: #2c3e50;
  border-radius: 4px;
}

.right-centered-content {
  display: flex;
  justify-content: flex-end;
}

.top-line{
  width: 100%;
  background-color: #2c3e50;
  border: 2px solid black;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

/*.scrollable-container {
  top: 60px;
  width: 80%;
  position: absolute;
  margin-left: 250px;
  padding: 20px;
  bottom: 0;
  !*left: 20%;去掉否则 /image 右边显示不了内容*!
  overflow-y: auto;
  height: calc(100vh - 60px);
  background-color: #f5f5f5; !* 右边内容背景 ：#2c3e50*!
  padding: 20px;
  !*  margin-left: 250px;*!
}*/
.scrollable-container {
  width: 80%;
  top: 60px;
  width: 80%;
  position: absolute;
  padding: 20px;
  bottom: 0;
  overflow-y: auto;
  height: calc(100vh - 60px);
  background-color: #f5f5f5;
  transition: width 0.3s ease; /* 添加宽度过渡效果 */
}

.main-aside[class*="isCollapse-true"] +.scrollable-container {
  width: calc(100% - 40px); /* 当侧边栏收缩时，调整右侧容器的宽度 */
}

.main-aside:not([class*="isCollapse-true"]) +.scrollable-container {
  width: calc(100% - 250px); /* 当侧边栏展开时，调整右侧容器的宽度 */
}

.scrollable-container::-webkit-scrollbar {
  width: 8px;
}

.scrollable-container::-webkit-scrollbar-track {
  background-color: #eaeaea;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 4px;
}




.copyright-info {
  text-align: center;
  margin-top: auto;
  font-size: 12px;
  color: #888; /* 调淡版权信息颜色 */
}

.el-icon {
  cursor: pointer; /* 让图标可点击时显示手型 */
}

.el-menu-item {
  transition: background-color 0.3s ease; /* 添加菜单项的过渡效果 */
}

.el-menu-item:hover {
  background-color: #e0e0e0; /* 鼠标悬停在菜单项上的背景色 */
}

/* 新增样式，当 isCollapse 为 true 时，将侧边栏宽度设置为图标大小 */
.main-aside[class*="isCollapse-true"] {
  width: 40px;
}

/*.right-body {
  flex: 1; !* 让该容器占据剩余空间 *!
  transition: width 0.3s ease; !* 添加过渡效果，使其平滑变化 *!
}*/
/*
.right-body {
  flex: 1;
  transition: width 0.3s ease, margin-left 0.3s ease; !* 添加 margin-left 的过渡效果 *!
}

.main-aside[class*="isCollapse-true"] +.scrollable-container.right-body {
  margin-left: 40px; !* 当侧边栏收缩时，右侧内容的左边距设置为与收缩后的侧边栏宽度一致 *!
}

.main-aside:not([class*="isCollapse-true"]) +.scrollable-container.right-body {
  margin-left: 250px; !* 当侧边栏展开时，右侧内容的左边距设置为侧边栏的宽度 *!
}
*/
/*
.right-body {
  flex: 1;
  transition: width 0.3s ease, margin-left 0.3s ease, transform 0.3s ease;
  margin-left: 252px; !* 初始时与展开的侧边栏保持一段距离 *!
  transform: perspective(1000px) rotateY(2deg); !* 添加一个初始的 3D 旋转效果 *!
}

.main-aside[class*="isCollapse-true"] +.scrollable-container.right-body {
  margin-left: 42px; !* 当侧边栏收缩时，右侧内容的左边距设置为与收缩后的侧边栏宽度一致并加上一段距离 *!
  transform: perspective(1000px) rotateY(0deg); !* 当侧边栏收缩时，调整右侧内容的 3D 旋转效果 *!
}

.main-aside:not([class*="isCollapse-true"]) +.scrollable-container.right-body {
  margin-left: 252px; !* 当侧边栏展开时，右侧内容的左边距设置为侧边栏的宽度加上一段距离 *!
  transform: perspective(1000px) rotateY(2deg); !* 当侧边栏展开时，保持右侧内容的 3D 旋转效果 *!
}
*/



/* 新增样式，当 isCollapse 为 true 时，将侧边栏宽度设置为图标大小 */
.main-aside[class*="isCollapse-true"] {
  width: 40px;
}

.right-body {
  flex: 1;
  transition: width 0.3s ease, margin-left 0.3s ease, transform 0.3s ease;
  margin-left: 217px; /* 初始时与展开的侧边栏保持一段距离 */
}

.main-aside[class*="isCollapse-true"] +.scrollable-container.right-body {
  margin-left: 42px; /* 当侧边栏收缩时，右侧内容的左边距设置为与收缩后的侧边栏宽度一致并加上一段距离 */
}

.main-aside:not([class*="isCollapse-true"]) +.scrollable-container.right-body {
  margin-left: 217px; /* 当侧边栏展开时，右侧内容的左边距设置为侧边栏的宽度加上一段距离 */
}


</style>
