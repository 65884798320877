//中文模板
export default {
    readMore: "更多",
    header: {
        home: "主页",
        services: "服务",
        cases: "案例",
        news: "新闻",
        service: {
            tech: "技术服务",
            buy: "采购代理"
        },
        about: {
            us: "关于我们",
            jobs: "加入我们",
            company: "我们公司",
            team: "我们团队",
        },
        contact: "联系方式",
        language: "语言",
    },
    about: {
        team: {
            title: "我们的团队",
            content: "        善理科技核心团队成员至少有二十年的汽车行业经验，技术团队分别来自全球新能源汽车领导品牌比亚迪，国际著名汽车OEM如通用汽车，长城汽车，长安汽车等，全球知名汽车工程服务公司如麦格纳，Roush，欧立腾等以及汽车零部件上市公司。\n" +
                "        我们的团队有强大的汽车技术背景，熟悉中国汽车行业丰富的供应链资源。\n"
        },
        company: {
            title: "关于公司",
            introduce: "公司简介",
            content: "        善理科技成立于2017年，拥有100多名团队成员，我们拥有成熟的项目管理经验和先进的整车开发流程。核心业务包括：汽车产品开发咨询，汽车造型设计，汽车工程结构设计，汽车仿真模拟分析，汽车模型和展车制作，汽车制造技术服务等。\n" +
                "        我们为超过20家主机厂和零部件企业提供服务。我们在整车研发领域有超过10多个成功的项目。\n" +
                "        同时我们还为国外OEM在中国提供零部件采购咨询业务，包括寻找供应商，技术咨询，工厂审核，产品质量控制等服务内容。\n" +
                "        我们也与国外的工程公司合作，提供本地化工程技术服务。\n"
        }
    },
    home: {
        company: {
            content: "上海善理汽车科技有限公司"
        },
        slogan: {
            made: "中国制造",
            server: "服务全球"
        },
        partners: {
            title: "合作伙伴",
            international: "国际工程服务合作伙伴",
            oem: "中国汽车设备制造商",
            motor: "摩托车品牌",
            component: "零部件公司",
            usa: "美国",
            india: "印度",
            france: "法国",
            canada: "加拿大",
            japan: "日本",
            china: "中国",
            german: "德国",
            kenya: "肯尼亚",
        }
    },
    contact: {
        slogan: "我们可以提供单一的专家或快速组建合适的团队。我们很乐意讨论善理汽车如何与您合作。请联系以下区域业务发展团队成员：",
        shanghai: "上海",
        nanjing: "南京"
    },
    news: {
        title: "公司最新资讯"
    },
    job: {
        title: "空缺职位",
        welcome: "欢迎来到善理，在这里您可以找到所有善理公司和业务领域的所有空缺职位。如果您的偏好和专业知识与我们目前的任何职位空缺不匹配，请发送您的简历到我们的邮箱。因为很有可能，我们很快就会有一个与你、你的热情和你的技能相匹配的职位空缺。",
        about: "关于工作",
        location: "工作地点",
        type: "工作类型",
        desc: "职位描述",
        require: "任职要求",
        salary: "薪资待遇",
        experience: "经验要求",
        qualification: "学历要求",
        contact: "联系方式",
        contactInfo: "请通过邮箱投递您的简历\n" +
            "HR:张三\n" +
            "电话:13888888888\n" +
            "邮箱:test{'@'}shanli.com",
    }
}
