//英文模板
export default {
    readMore: "Read More",
    header: {
        home: "Home",
        services: "Services",
        cases: "Cases",
        news: "News",
        service: {
            tech: "Technical services",
            buy: "Purchasing agent"
        },
        about: {
            us: "About Us",
            jobs: "Jobs",
            company: "Company",
            team: "Team",
        },
        contact: "Contact",
        language: "Language",
    },
    about: {
        team: {
            title: "Our team",
            content: "        Our main team members have more than 20 years of experience in the automotive industry. Our engineers are from the global new energy vehicle leading brand BYD, and internationally famous automotive OEMS such as GM, GWM and Changan etc, and internationally famous automotive engineering companies such as Magna, EDAG, Roush and Alten etc, and auto parts listed companies.\n" +
                "        Our team has a strong background in automotive technology. And we are familiar with the rich supplier resources of the Chinse automotive industry.\n"
        },
        company: {
            title: "About Company",
            introduce: "Who We Are",
            content: "        Shanli was founded in 2017，We currently have more than 100 professional automotive engineers. We have extensive project management experience and advanced vehicle development process. Our main business include: Automotive product development technical consulting; automotive styling development; automotive engineering structure development; Automotive simulation analysis; Automotive model and show car production; Automotive manufacturing technical consulting etc.\n" +
                "        We have provided technical services to more than 20 OEMs and tier one. And also we have more than 10 successful cases of whole vehicle development.\n" +
                "        We also provide procurement consulting services for foreign OEMs in China. Services content include: search for suppliers, technical communication; potential suppliers audit, PQC etc.\n" +
                "        We also cooperation with foreign engineering companies to provide local Chinese technical services. \n"
        }
    },
    home: {
        company: {
            content: "Shanghai Shanli Automobile Technology Co., Ltd"
        },
        slogan: {
            made: "Made in China",
            server: "Serving the World"
        },
        partners: {
            title: "Partners",
            international: "International engineering services partners",
            oem: "Chines Automotive OEM",
            motor: "Motorcycle Brand",
            component: "Component Company",
            usa: "USA",
            india: "India",
            france: "France",
            canada: "Canada",
            japan: "Japan",
            china: "China",
            german: "German",
            kenya: "Kenya",
        }
    },
    contact: {
        slogan: "We can provide single experts or set up suitable teams quickly. We’d love to discuss how shanli can work with you. Please contact a member of our regional business development team below:",
        shanghai: "shanghai",
        nanjing: "nanjing"
    },
    news: {
        title: "Latest Company Information"
    },
    job: {
        title: "Available Jobs",
        welcome: "Welcome to Shanli and to the place where you’ll find all vacant positions at all Shanli companies and business areas. If your preferences and expertise don’t match any of our current vacancies, please send your CV by email to us anyway. Because very likely, we’ll soon have an opening that matches you, your passion and your skills.",
        about: "About Job",
        location: "Job Location",
        type: "Job Type",
        desc: "Job Description",
        require: "Require Skills",
        salary: "Salary",
        experience: "Experience",
        qualification: "Qualification",
        contact: "Contact",
        contactInfo: "Please send your CV by email to us\n" +
            "HR:张三\n" +
            "Tel:13888888888\n" +
            "email:test{'@'}shanli.com",
    }
}
