//公共方法
import router from '../router/index.js';
class MainService {
  constructor() {
    this.throttle = this.throttleFn(); // 节流
    this.debounce = this.debounceFn(); // 防抖
  }
  /**
   * toast
   * */
  toast(msg) {
    this.$message(msg);
  }

  modal(title, message, cb) {
    this.$alert(title, message, {
      confirmButtonText: '确定',
      callback: action => {
        this.$message({
          type: 'info',
          message: `action: ${ action }`
        });
      }
    }).then(() => {
      cb && cb();
    });
  }

  /**
   * 节流适用场景：表单提交
   * @param {function} handler 进行防抖的函数
   * @param {number} wait 等待时间ms
   */
  throttleFn() {
    var lastTime = 0;
    return function (handler, wait = 1000) {
      var nowTime = new Date().getTime();
      if (nowTime - lastTime > wait) {
        handler && handler();
        lastTime = nowTime;
      }
    };
  }

  /**
   * 防抖适用场景：搜索框
   * @param {function} handler 进行防抖的函数
   * @param {number} delay 等待时间ms
   */
  debounceFn() {
    var timer = null;
    return function (handler, delay = 1000) {
      clearTimeout(timer);
      timer = setTimeout(function () {
        handler && handler();
      }, delay);
    };
  }


  // 路由跳转
  link(name, params = {}, type = 'push') {
    // this.throttle(() => {
    if (type === 'replace') {
      router.replace({
        name,
        params,
      });
      return;
    }
    router.push({
      name,
      params,
    });
  }
}

export default new MainService();
