<template>
  <div :class="'cell' + (isHome ? ' home-cell':'')" @click="showImageDetail">
    <div class="more-info"><el-icon><MoreFilled/></el-icon></div>
    <div class="img-div"><img :src="info.avatar" alt="" :class="'view-img' + (isHome ? ' home-img':'')"/></div>
    <div class="detail-info">
      <el-row><span>{{info.createBy}}</span></el-row>
      <el-row align="middle" justify="space-between">
        <el-col :span="8">
          <el-row align="middle" justify="space-evenly">
            <el-icon><Pointer/></el-icon><div>{{info.likeNum}}</div>
<!--            <el-icon><Star/></el-icon><div>{{info.collect}}</div>-->
          </el-row>
        </el-col>
        <el-col :span="4" :offset="12">
          <el-tooltip placement="left" effect="light">
            <template #content>
              <el-form label-width="120">
                <el-form-item label="正向提示词(Prompt):">
                  <el-input type="textarea" :model-value="info.prompt" readonly="readonly" autosize="true">
                  </el-input>
                </el-form-item>
                <el-form-item label="负向提示词(negativePrompt):">
                  <el-input type="textarea" :model-value="info.negativePrompt" readonly="readonly" autosize="true">
                  </el-input>
                </el-form-item>
                <el-form-item label="采样方法(Simple):">
                  {{info.samplingMethod}}
                </el-form-item>
                <el-form-item label="迭代步数(Steps):">
                  {{info.samplingStep}}
                </el-form-item>
                <el-form-item label="相关性(CFG):">
                  {{info.cfgScale}}
                </el-form-item>
                <el-form-item label="种子(Seed):">
                  {{info.seed}}
                </el-form-item>
                <el-form-item label="模型(Model):">
                  {{info.modelId}}
                </el-form-item>
              </el-form>
            </template>
            <el-icon><InfoFilled/></el-icon>
          </el-tooltip>
        </el-col>
      </el-row>
    </div>
  </div>
  <el-dialog v-model="dialogVisible" width="50%" append-to-body draggable>
    <ImageDetail :info="info"/>
  </el-dialog>
</template>

<script setup>
import ImageDetail from "@/views/ImageDetail.vue";
import {ref} from "vue";

defineProps(['info', 'isHome'])
const dialogVisible = ref(false)
const showImageDetail = () => {
  dialogVisible.value = true
}
</script>

<style scoped>
.cell {
  overflow: hidden;
  border-radius: .75rem;
  margin: 10px;
  display: flex;
  position: relative;
}
.home-cell {
  height: 380px;
}
.more-info {
  position: absolute;
  padding: 12px;
  display: flex;
  z-index: 2;
}
.detail-info {
  position: absolute;
  padding: 12px;
  bottom: 0;
  width: 100%;
  z-index: 2;
}
.view-img {
  width: 100%;
  transition: transform 0.5s; /*规定设置过渡效果的CSS属性的名称和时间*/
  display: block;
}
.home-img {
  object-fit: cover;
  height: 100%;
}
.view-img:hover {
  transform: scale(1.1);  /*放大1.1倍*/
  opacity:0.6;
}
.img-div:hover {
  background: rgba(0,0,0);
  z-index: 1;
}
</style>
