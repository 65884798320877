// axios封装
import axios from "axios"
const { VITE_APP_APIBASEURL } = { ...(import.meta.env) }
import Main from './main.service.js'
import i18n from "../lang/i18n"
const errCode = [] //错误状态，但是正常返回弹框

class AxiosService {
	constructor(baseUrl) {
		this.baseUrl = baseUrl
	}
	getConfig() {//公共基础配置
		const config = {
			baseURL: this.baseUrl,
			requestNum : 1,
			headers: { //header公共参数
			}
		}
		return config
	}
	interceptor(instance,options) {//给实例配置拦截器
		// 添加请求拦截器
		instance.interceptors.request.use(async config => {
			return config
		}, error => {
			// 对请求错误做些什么
			return Promise.reject(error);
		});

		// 添加响应拦截器
		instance.interceptors.response.use(async response => {
			const { status, data } = { ...response }
			if (status == 200) { //请求成功
				if(data && data.code == 0) {
					return data;
				}else if(data && errCode.includes(data.code)) {
					return Promise.reject({
						code : data.code,
						data : data
					});
				}else{
					if(!options.noShowErrMessage) { //需要错误提示
						i18n.global.t('messages.common.loading')
						Main.modal(i18n.global.t('messages.common.tip'),i18n.global.t('messages.common.requestErr'))
					}
					return Promise.reject(response);
				}
			} else { //请求失败
				if(!options.noShowErrMessage) { //不需要错误提示
					Main.modal(i18n.global.t('messages.common.tip'),i18n.global.t('messages.common.requestErr'))
				}
				return Promise.reject(response);
			}
		}, error => { //请求失败
			if(!options.noShowErrMessage) { //不需要错误提示
				Main.modal(i18n.global.t('messages.common.tip'),i18n.global.t('messages.common.requestErr'))
			}
			return Promise.reject(error);
		});
	}
	request(options) {//新建实例并配置实例并可传参
		const instance = axios.create() //新建axios实例
		options = { ...this.getConfig(), ...options } //参数（baseURL、其他参数）
		this.interceptor(instance,options) //给axios实例添加拦截器
		return instance(options) //给axios实例添加参数
	}

}

export default new AxiosService(VITE_APP_APIBASEURL)

