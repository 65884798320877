// src/router/index.js

// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'

const routes = [

  {
    path: '/',
    name: 'home',
    component: HomePage
  },
/*  {
    path: '/dongtu',
    name: 'dongtu',
    component: () => import('@/views/DigitalPage.vue')

  },*/

  {
    path: '/images',
    name: 'images',
    component: () => import('@/views/ImagePage.vue')
  },
  {
    path: '/models',
    name: 'models',
    redirect: '/models4'  // 添加重定向规则
  },
  {
    path: '/models1',
    name: 'models1',
    component: () => import('@/components/ModelCell-One.vue')
  },
  {
    path: '/models2',
    name: 'models2',
    component: () => import('@/components/ModelCell-Two.vue')
  },
  {
    path: '/models4',
    name: 'models4',
    component: () => import('@/views/ModelPage.vue')
  },
  {
    path: '/models6',
    name: 'models6',
    component: () => import('@/components/ModelCell_Six.vue')
  },
  {
    path: '/models8',
    name: 'models8',
    component: () => import('@/components/ModelCell_Eight.vue')
  },
  {
    path: '/models9',
    name: 'models9',
    component: () => import('@/components/ModelCell-Nine.vue')
  },
  {
    path: '/models10',
    name: 'models10',
    component: () => import('@/components/ModelCell-Ten.vue')
  },
  {
    path: '/vr',
    name: 'VR',
    component: () => import('@/views/VrPage.vue')
  },
  {
    path: '/meta',
    name: 'meta',
    component: () => import('@/views/MetaPage.vue')
  },
  {
    path: '/metb',
    name: 'metb',
    component: () => import('@/views/MetaBPage.vue')
  },
  {
    path: '/dongtu',
    name: 'dongtu',
    component: () => import('@/views/DigitalPage.vue')
  },
  // 其他路由...
  {
    path: '/game',
    name: 'game',
    component: () => import('@/views/GamePage.vue')
  },
  {
    path: '/planet',
    name: 'planet',
    component: () => import('@/views/OfficePage.vue')
  },
  {
    path: '/hr',
    name: 'hr',
    component: () => import('@/views/HumanResources.vue')
  },
]

// const router = createRouter({
//   history: createWebHistory(import.meta.env.BASE_URL),
//   routes,
// })

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL), // 改为 createWebHashHistory
  routes,
})

export default router
