<template>
  <el-row class="sub-title">
    <h2>典型业务</h2>
  </el-row>
  <el-row align="middle" class="banner-row">
    <el-col :span="18" :offset="0">
      <el-carousel trigger="hover" height="400px">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index"     class="container">
          <img :src="item.img" alt="banner" class="banner-img">

        </el-carousel-item>
      </el-carousel>
    </el-col>
  </el-row>

  <el-row class="sub-title">
    <h2>精选作品</h2>
  </el-row>
  <el-row>
    <el-col :span="6" v-for="item in images"
            :key="item.id">
      <ImageCell :info="item" :isHome="true"/>
    </el-col>
  </el-row>

</template>

<script setup>
import {onBeforeMount, ref} from "vue"
import { getImageList } from "@/api/request.js"
const images = ref([])
import ImageCell from "@/components/ImageCell.vue";

import {getBannerNoPermission} from "../api/request";
const bannerList = ref([])

onBeforeMount(() => {
  bannerInit();
  pageInit();

})


const pageInit = () => {
  let params = {
    pageSize: 8,
    pageNum: 1,
  }
  Promise.all([getImageList(params)]).then((vals) => {
    if(vals && vals.length) {
      vals.map((item,index) => {
        if(item && item.code === 0 && item.rows) {
          if(index === 0) {
            images.value = item.rows
          }
        }
      })
    }
  }).catch((err) => {
    console.log(err)
  })

}

const bannerInit = () => {
  let params = {
    pageSize: 10,
    pageNum: 1,
    // isAsc:"asc",
    // img:""
  }
  console.log("----banner----")
  Promise.all([getBannerNoPermission(params)]).then((vals) => {

    if(vals && vals.length) {
      console.log("----banner2----")

      vals.map((item,index) => {
        if(item && item.code === 0 && item.rows) {
          if(index === 0) {
            bannerList.value = item.rows
          }
        }
      })
    }
  }).catch((err) => {
    console.log(err)
  })

}

</script>

<style scoped>
/*.banner {
  width: 100%;
  height: 80%;

}*/

.sub-title {
  color: #181818;
  padding-bottom: 20px;
}

.banner-row {
  position: relative;
}

.el-carousel {
  width: 90%;
  height: 400px;
  margin-left: 20%; /* 增加 margin-left 的值，使轮播图更靠右 */
  perspective: 1000px;
  justify-content: space-between; /* 让子元素在水平方向两端对齐 */
}

.el-carousel-item {
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
}

.container {
/*  width: 100%;
  height: 100%;
  border: 1px solid black;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); !* 添加阴影以增加立体感 *!*/

  display: flex;
  justify-content: center;
  align-items: center;
}

/*.banner-img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  transform: rotateY(30deg);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}*/


.el-carousel {
  width: 95%;  /* 微调宽度 */
  height: 450px;  /* 增加高度 */
  background-color: #f5f5f5;  /* 更改背景色 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);  /* 调整阴影 */
}

.el-carousel-item {
  transition: transform 0.8s ease;  /* 延长过渡时间 */
}

.banner-img {
  width: 100%;  /* 充满容器 */
  height: 100%;
  object-fit: fill;  /* 更改图片填充方式 */
  border-radius: 10px;  /* 增加圆角 */

}


.el-carousel:hover.el-carousel-item {
  transform: rotateY(-30deg);
}



</style>

