import AxiosService from '@/services/request.service.js'
import qs from 'qs';

// 获取图片列表
export function getImageList(data) {
    return AxiosService.request({
        url: '/api/system/image/rList',
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
    })
}

export function getImageListAll(data) {
    return AxiosService.request({
        url: '/api/system/image/list',
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
    })
}

// 获取模型列表
export function getModelList(data) {
    return AxiosService.request({
        url: '/api/system/model/list',
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
    })
}


// 获取banner列表
export function getBannerNoPermission(data) {
    return AxiosService.request({
        url: '/api/system/banner/bannerList',
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
    })
}

export function getBannerList(data) {
    return AxiosService.request({
        url: '/api/system/banner/list',
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
    })
}
